import * as React from "react"
import { Link } from "gatsby"

import "../styles/footer.css"

const Footer = () => {
  return (
      <div className="footer">            
          <div className="footerWrapper">
          <div>
          <p className="footerLabel">IV Restaurant Berlin</p>
          <p className="footerContent">Immanuelkirchstraße 7</p>
              <p className="footerContent">10405 Berlin</p>
              </div>
              <div>
              <p className="footerLabel">Öffnungszeiten</p>
              <p className="footerContent">Täglich 12:00 - 22:00</p>
              </div>
              <div>
                  <p className="footerLabel">Kontakt</p>
                  <a href="tel:+493055285900" className="footerContent">Tel: 030 55285900</a>
              </div>
              <div>
                  <p className="footerLabel">Rechtliches</p>
                  <Link to= "/impressum" className="footerContent">Impressum</Link>
                  <Link to= "/impressum#datenschutz" className="footerContent">Datenschutz</Link>
              </div>
          </div>
          
     </div>
  )
}

export default Footer