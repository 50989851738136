import React from "react"
import TopBar from "../components/topBar"
import Header from "../components/header"
import Footer from "../components/footer"

import "../styles/global.css"

export default function Layout({ children }) {
  return (
    <main>
      <TopBar/>
      <Header/>
      {children}
      <Footer/>
    </main>
  )
}