import * as React from "react"
import { useState } from "react";
import { Link } from "gatsby"

import "../styles/header.css"

import { MdClear, MdDehaze } from 'react-icons/md';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  
  function toggleMenu() {
    setMenuOpen(currentState => !currentState)
  }

  return (
      <div className="header">
          <div className="headerBox" style={{display: menuOpen? "flex" : "none"}}>
              <Link onClick={toggleMenu} to="/" id="homelink" className="headerLink">Home</Link>
              <Link onClick={toggleMenu} to="/menu" className="headerLink">Menü</Link>
              <Link onClick={toggleMenu} to="/bar" className="headerLink">Bar & Drinks</Link>
          </div>
          <div id="headerName" style={{display: "flex", alignItems: "center", justifyContent: "left", width: "100%"}}>
              {menuOpen ? <MdClear className="burgerMenuButton" size="42" onClick={toggleMenu}/> : <MdDehaze className="burgerMenuButton" size="42" style={{margin: "0 1rem", display: "none"}} onClick={toggleMenu}/>}
              <Link to="/">
              <h1>IV Restaurant</h1>
          </Link> 
          </div>

          <div className="headerBox" id="headerReservation" style={{display: menuOpen? "flex" : "none"}}>
              <a className="headerLink" target="_blank" rel="noopener noreferrer" href="https://widget.thefork.com/5e398011-7e82-4b14-8ea3-59c5b320f5fa" style={{marginRight: "2rem"}}>Reservierung</a>
          </div>
     </div>
  )
}

export default Header